import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/home/Home";
import ErrorBoundary from "../utilities/ErrorBoundary";
import Admin from "../pages/admin/Admin";
import View from "../pages/view/View";
import Collapse from "../pages/collapes/Collapse";
import ViewMSTeams from "../pages/msteams/ViewMSTeams";
import CollapseMSTeams from "../pages/msteams/CollapesMSTeams";


const routes = [

    {
        path: '/',
        element: <Home />,
        errorElement: <ErrorBoundary />,
    }
    ,
    {
        path: '/admin',
        element: <Admin />,
        errorElement: <ErrorBoundary />
    }
    ,
    {
        path: '/view',
        element: <View />,
        errorElement: <ErrorBoundary />
    }
    ,
    {
        path: '/collapse',
        element: <Collapse />,
        errorElement: <ErrorBoundary />
    }
    ,
    {
        path: '/view_ms_teams',
        element: <ViewMSTeams />,
        errorElement: <ErrorBoundary />
    }
    ,
    {
        path: '/collapse_ms_teams',
        element: <CollapseMSTeams />,
        errorElement: <ErrorBoundary />
    }
]
const Router = createBrowserRouter(routes);

export default Router;