import { useState } from "react";
import { db, fireViews, setMeetingLink, updateEnvUrl, updateMeetingUrl } from "../../firebase.config";
import { useEffect } from "react";
const Admin = () => {

  const [curr_view, setCurr_view] = useState("teams-view")
  const [switchBtnText, setSwitchBtnText] = useState("Environment")
  const [meetingUrl, setMeetingUrl] = useState("")
  const [showMeetingError, setShowMeetingError] = useState(false)
  const [envUrl, setEnvUrl] = useState("")
  const [showEnvError, setShowEnvError] = useState(false)
  const [lastMeetingId, setLastMeetingId] = useState(null);

  useEffect(() => {
    const fetchLastMeeting = async () => {
      try {
        const querySnapshot = await db.collection('teams').orderBy('created', 'desc').limit(1).get();
        if (!querySnapshot.empty) {
          const lastDocument = querySnapshot.docs[0];
          setLastMeetingId(lastDocument.id);
        }
      } catch (error) {
        console.error("Error fetching last meeting:", error);
      }
    };

    // Call the function to fetch the last meeting
    fetchLastMeeting();
  }, []); 

  useEffect(() => {
    setSwitchBtnText(curr_view === "env-view" ? "MS Teams" : "Environment")
  }, [curr_view])
  const switchViews = (params) => {
    setCurr_view(curr_view === "teams-view" ? "env-view" : "teams-view");
    fireViews(curr_view, lastMeetingId);
  }




  const addMeetingUrl = () => {
    // Check if meetingUrl is null, undefined, or an empty string
    if (!meetingUrl || meetingUrl.trim() === '') {
      setShowMeetingError(true);
      return false;
    } else {
      updateMeetingUrl(meetingUrl, lastMeetingId);
    }
  };

  const addEnvUrl = () => {
    if (!envUrl) {
      setShowEnvError(true)
      return false
    } else {
      updateEnvUrl(envUrl, lastMeetingId)
    }
  }


  return (
    <section id="" className="flex flex-col h-screen" >
      <header className="bg-success text-white p-4  text-center">
        <h1 className="text-3xl">Welcome To  Admin Panel </h1>
      </header>
      <div className="flex container mx-auto py-8 justify-center grow flex-col gap-9 ">
        <h1 className="text-2xl font-semibold text-center  "> Control Meeting & Environment views  </h1>

        <div className="flex w-full justify-evenly ">
          <div className="flex justify-center items-center flex-col gap-3 w-2/5 ">

            <div className="mockup-browser border bg-base-300 w-full">
              <div className="mockup-browser-toolbar text-center">
                <h1 className="text-xl font-semibold text-center w-full ">  Meeting  </h1>
              </div>
              <div className="flex justify-center px-4 py-16 bg-base-200"><div className="flex justify-center items-center flex-col  gap-3  w-full" >
                <p className=" text-md  text-start"> Meeting URL </p>
                <div className="flex justify-center items-center   gap-3  w-full" >
                  <input type="text" onChange={(e) => { setMeetingUrl(e.target.value); setShowMeetingError(false);console.log(e.target.value) }} placeholder="Paste meeting url here" className="input input-bordered w-full max-w-xs" />
                  <button id="add_meeting_link" onClick={addMeetingUrl} type="button" className="btn-xl btn btn-neutral">Add</button>
                </div>
                <p className={`text-red-600 text-sm ${showMeetingError ? 'opacity-100' : 'opacity-0'}  `}> Enter Environment link please! </p> 

              </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center flex-col gap-3 w-2/5 ">
            <div className="mockup-browser border bg-base-300 w-full">
              <div className="mockup-browser-toolbar text-center">
                <h1 className="text-xl font-semibold text-center w-full ">  Environment  </h1>
              </div>
              <div className="flex justify-center px-4 py-16 bg-base-200"><div className="flex justify-center items-center flex-col  gap-3  w-full" >
                <p className=" text-md  text-start"> Environment URL </p>
                <div className="flex justify-center items-center   gap-3  w-full" >
                  <input type="text" onChange={(e) => { setEnvUrl(e.target.value); setShowEnvError(false) }} placeholder="Paste meeting url here" className="input input-bordered w-full max-w-xs" />
                  <button id="" onClick={addEnvUrl} type="button" className="btn-xl btn btn-neutral">Add </button>
                </div>
                <p className={`text-red-600 text-sm ${showEnvError?'opacity-100':'opacity-0'}  `}> Enter Environment link please! </p> 
              </div>
              </div>
            </div>
          </div>

        </div>
   


        <div className="switch_btn_wrapper flex-col gap-3 my-10">
          <button id="switch_btn" type="button" onClick={switchViews} className="btn-xl btn btn-success">{switchBtnText}</button>
        </div>




      </div>
     
    
    </section>
  )
}

export default Admin