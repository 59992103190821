
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { db, setMeetingLink } from "../../firebase.config";
import { useEffect, useState } from "react";
import { useUpdateEffect } from 'react-use';
import Iframe from 'react-iframe'
import Loading from "../../utilities/Loading";
import { Zoom } from "react-toastify";
import Meeting from "../meeting/Meeting";
import './collapes.css'
import { FunctionalIFrameComponent } from "./FunctionalIFrameComponent";
const Collapse = () => {
  //  setMeetingLink()
  const [meetingUrl, setMeetingUrl] = useState("https://team-sdk-control.nexus-ecosystem.com/?teamsLink=https%3A%2F%2Fteams.microsoft.com%2Fl%2Fmeetup-join%2F19%3AStkY2Pg7K1dyYwoHC3SLFNF6JHbzQxuhjDtdUVtSQRg1%40thread.tacv2%2F1715071550089%3Fcontext%3D%257B%2522Tid%2522%3A%2522fdb3569b-ebc6-4501-878c-cfb2391769b1%2522%2C%2522Oid%2522%3A%2522114e566f-7b12-40ad-a50e-1d2bc42143f4%2522%257D")
  const [showEnv, setShowEnv] = useState(false)
  const [showMeeting, setShowMeeting] = useState(true)
  let is_First_read = false;

  // Listen for changes in the 'meetingUrl' state
  // useEffect(() => {
  //   console.log("gggggggggggggggggggggggggggg")
  // }, [meetingUrl]);

  // Firestore query to listen for changes
  useEffect(() => {
    const unsubscribe = db.collection("teams")
      .orderBy("created", "desc")
      .limit(1)
      .onSnapshot((snapshot) => {
        snapshot.forEach(function (doc) {
          console.log(doc.data().view);
          console.log(doc.data());
          is_First_read && toggleView(doc.data().view);
          is_First_read = true;
          console.log(doc.data().view);
        });
      });

    // Cleanup function to unsubscribe from the snapshot listener
    return () => unsubscribe();
  }, []);


 
  console.log(meetingUrl)

  const toggleView = (view_to_show) => {
    if (view_to_show === "teams-view") {
      setShowEnv(true)
      setShowMeeting(false)
    } else {
      setShowEnv(false)
      setShowMeeting(true)
    }
  }

  return (
    <div className="">
      <section id="main_wrapper" className="teams-view">
        {showMeeting ?
          <Meeting />
    : null
        }
        {showEnv ?
         <div className=" w-full flex ">
            <div className="w-4/5 ">
              <div id="page_1" className=" teams w-full h-full ">
                <Iframe url="https://demo.360-simulation.com/Environment%2028/"
                  width="100%"
                  id="teams"
                  className=""
                  height="100%"
                  loading={"...Please wait while loading your Meeting!"}
                />
              </div>
              </div>
            <div className="w-1/5  h-screen">
              {/* <FunctionalIFrameComponent title="functional-iframe">
                <Meeting />
              </FunctionalIFrameComponent> */}
              <Meeting />
            </div>
        </div>
      
          : null
        }
      </section>
    </div>
  )
}

export default Collapse
