import { Link } from 'react-router-dom'

const Home = ({ Children }) => {
  return (
    <div>
      <div  className="flex flex-col h-screen">
        <header className="bg-blue-300 text-white p-4  text-center">
          <h1 className="text-3xl">Welcome To Environment Controller </h1>
        </header>
        <main className="p-4 flex justify-center items-center grow flex-col gap-5">
          <div className="bg-gray-200 p-8 rounded-lg flex justify-center items-center gap-3 shadow-md">
            <Link className="bg-green-500 shadow-md w-28 text-center text-white p-2 rounded-lg hover:text-white hover:scale-110" to="admin" > Admin</Link>
          </div>
          {/* <div className="bg-gray-200 p-8 rounded-lg flex justify-center items-center gap-8 shadow-md">
            <Link className="bg-blue-600 w-44 shadow-md  text-center text-white p-2 rounded-lg hover:text-white hover:scale-110" to="view" >Zoom_FullScreen</Link>
            <Link className="bg-blue-600 w-44 shadow-md  text-center text-white p-2 rounded-lg hover:text-white hover:scale-110" to="collapse" > Zoom_collapse</Link>
          </div> */}
          <div className="bg-gray-200 p-8 rounded-lg flex justify-center items-center gap-8 shadow-md">
            {/* <Link className="bg-indigo-600 w-44 shadow-md  text-center text-white p-2 rounded-lg hover:text-white hover:scale-110" to="view_ms_teams" > MS_Teams_FullScreen</Link> */}
            <Link className="bg-indigo-600 w-44 shadow-md  text-center text-white p-2 rounded-lg hover:text-white hover:scale-110" to="collapse_ms_teams" target="_blank" >MS_Teams Collapse</Link>
          </div>
        </main>
      </div>
    </div>
  )
}

export default Home
