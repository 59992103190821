import { ZoomMtg } from "@zoom/meetingsdk";
import hero from"../../assets/zoomhero.jpg"
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
function Meeting() {
    var sdkKey = "uOQR_vJfT0KsmFJTfbInA";
    var sdkSecret = "kaJPd6gQUJnT23Eec65Jqxn7JtXd4RaH";
    var meetingNumber = 5178310636;
    var role = 0;
    var userName = "React";
    var leaveUrl = "http://localhost:3000";

    let signature = "";
    function startMeeting() {
        ZoomMtg.generateSDKSignature({
            sdkKey,
            sdkSecret,
            meetingNumber: meetingNumber,
            role: role,
            success: (success) => {
                signature = success;
            },
            error: (error) => { },
        });
        document.getElementById("zmmtg-root").style.display = "block";

        ZoomMtg.init({
            leaveUrl: leaveUrl,
            patchJsMedia: true,
            success: (success) => {
                console.log("initialized", {
                    sdkKey: sdkKey,
                    signature: signature,
                    meetingNumber: meetingNumber,
                    passWord: "d4p3bL",
                    userName: userName,
                });
                ZoomMtg.join({
                    sdkKey: sdkKey,
                    signature: signature,
                    meetingNumber: meetingNumber,
                    passWord: "",
                    userName: userName,
                    success: (success) => {
                        console.log({ success });
                    },
                    error: (error) => {
                        console.log({ error });
                    },
                });
            },
            error: (error) => {
                console.log({ error });
            },
        });
    }
console.log("meeting component")
    return (
          
                <div className="hero min-h-screen" style={{ backgroundImage: `url(${hero})` }}>
                    <div className="hero-overlay bg-opacity-60"></div>
                    <div className="hero-content text-center text-neutral-content">
                        <div className="max-w-xl">
                            <h1 className="mb-5 text-5xl font-bold">Hello Zoom Meeting </h1>
                         
                            <button className="btn " onClick={startMeeting}>Join Meeting</button>

                        </div>
                    </div>
                </div>
       
    
    );
}
export default Meeting;
