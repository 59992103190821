import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyAe1zV87_vUNoPtar0kI2Ro6mLQFJqXShs",
    authDomain: "environment-control-a5256.firebaseapp.com",
    projectId: "environment-control-a5256",
    storageBucket: "environment-control-a5256.appspot.com",
    messagingSenderId: "332775514027",
    appId: "1:332775514027:web:d1f0163b2d220a0216fcee",
    measurementId: "G-GCDNW0JC6T"
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();

export const fireViews = (view,  docId) => {
    if (docId) {
        db.collection("teams").doc(docId).set({
            view,
            
            created: firebase.firestore.FieldValue.serverTimestamp()
        }, { merge: true })
            .then(() => {
                console.log("Document successfully updated");
            })
            .catch((error) => {
                console.error("Error updating document: ", error);
            });
    } else {
        // Add a new document if no document ID is provided
        db.collection("teams").add({
            view,
            
            created: firebase.firestore.FieldValue.serverTimestamp()
        })
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
    }
};




export const updateMeetingUrl = (link, docId) => {
    if (docId) {
        db.collection("teams").doc(docId).set({
            link,
            created: firebase.firestore.FieldValue.serverTimestamp()
        }, { merge: true })
            .then(() => {
                console.log("Document successfully updated");
            })
            .catch((error) => {
                console.error("Error updating document: ", error);
            });
    } else {
        // Add a new document if no document ID is provided
        db.collection("teams").add({
            link,
            created: firebase.firestore.FieldValue.serverTimestamp()
        })
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
    }
};
export const updateEnvUrl = (env_link, docId) => {
    if (docId) {
        db.collection("teams").doc(docId).set({
            env_link,
            created: firebase.firestore.FieldValue.serverTimestamp()
        }, { merge: true })
            .then(() => {
                console.log("Document successfully updated");
            })
            .catch((error) => {
                console.error("Error updating document: ", error);
            });
    } else {
        // Add a new document if no document ID is provided
        db.collection("teams").add({
            env_link,
            created: firebase.firestore.FieldValue.serverTimestamp()
        })
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
    }
};
