




import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { db, setMeetingLink } from "../../firebase.config";
import { useEffect, useState } from "react";
import Iframe from 'react-iframe'
import Meeting from "../meeting/Meeting";
const CollapseMSTeams = () => {
    //  setMeetingLink()
    const [meetingUrl, setMeetingUrl] = useState("")
    const [envUrl, setEnvUrl] = useState("")
    const [showEnv, setShowEnv] = useState(false)
    const [showMeeting, setShowMeeting] = useState(true)
    let is_First_read = false;

    // Listen for changes in the 'meetingUrl' state
    useEffect(() => {
        console.log("meetingUrl")
    }, [meetingUrl]);

    // Firestore query to listen for changes
    useEffect(() => {
        const unsubscribe = db.collection("teams")
            .orderBy("created", "desc")
            .limit(1)
            .onSnapshot((snapshot) => {
                snapshot.forEach(function (doc) {
                    is_First_read && toggleView(doc.data().view);
                    is_First_read = true;
                    setMeetingUrl(doc.data().link)
                    setEnvUrl(doc.data().env_link)
                    console.log("--------------------------------")
                    console.log(doc.data().env_link);
                    console.log("--------------------------------")
                });
            });

        // Cleanup function to unsubscribe from the snapshot listener
        return () => unsubscribe();
    }, []);





    console.log(meetingUrl)

    const toggleView = (view_to_show) => {
        if (view_to_show === "teams-view") {
            setShowEnv(true)
            setShowMeeting(false)
        } else {
            setShowEnv(false)
            setShowMeeting(true)
        }
    }

    return (
        <div className="">
            <section id="main_wrapper" className="teams-view">


{/* show and hide */}
                {/* <div className=" w-full flex ">
                    <div className={` ${showEnv ? 'w-4/5' : showMeeting ? "w-0" : 'w-0'} `}>
                        <div id="page_1" className=" teams w-full h-full ">
                            <Iframe url="https://demo.360-simulation.com/Environment%2028/"
                                width="100%"
                                id="teams"
                                className=""
                                allow="camera *; microphone *"
                                height="100%"
                                loading={"...Please wait while loading your Page!"}
                            />
                        </div>
                    </div>
                    <div className={` ${showEnv ? 'w-1/5' : showMeeting ? "w-full" : 'w-0'} h-screen  `}>
                        <Iframe url={meetingUrl}
                            width="100%"
                            id="teams"
                            className=""
                            allow=" camera *; microphone * ;accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;display-capture *;"
                            height="100%"
                            loading={"...Please wait while loading your Page!"}
                        />
                    </div>
                </div> */}


{/* replace components  */}
                {showMeeting ? <Iframe url={meetingUrl}
                    width="100%"
                    id="teams"
                    className=""
                    allow=" camera *; microphone * ;accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;display-capture *;"
                    referrerPolicy="strict-origin-when-cross-origin"
                    height="100%"
                    loading={"...Please wait while loading your Page!"}
                />

                    : null
                }
                {showEnv ?
                    <div className=" w-full flex ">
                        <div className="w-3/5 ">
                            <div id="page_1" className=" teams w-full h-full ">
                                <Iframe url={envUrl}
                                    width="100%"
                                    id="teams"
                                    className=""
                                    allow=" camera *; microphone * ;accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;display-capture *;"
                                    height="100%"
                                    loading={"...Please wait while loading your Page!"}
                                />
                            </div>
                        </div>
                        <div className="w-2/5 h-screen ">
                            <Iframe url={meetingUrl}
                                width="100%"
                                id="teams"
                                className=""
                                allow=" camera *; microphone * ;accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;display-capture *;"
                                height="100%"
                                loading={"...Please wait while loading your Page!"}
                            />

                        </div>
                    </div>
                    : null
                }
            </section>
        </div>
    )
}

export default CollapseMSTeams
